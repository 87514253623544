(function ($) {

    $(function () {

        $(document).trigger('contentLoaded');

        $('#field-registro-telefone-add-more-wrapper').on('keydown', '#edit-field-registro-telefone-und-0-value', function (e) {
            -1 !== $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) || (/65|67|86|88/.test(e.keyCode) && (e.ctrlKey === true || e.metaKey === true)) && (!0 === e.ctrlKey || !0 === e.metaKey) || 35 <= e.keyCode && 40 >= e.keyCode || (e.shiftKey || 48 > e.keyCode || 57 < e.keyCode) && (96 > e.keyCode || 105 < e.keyCode) && e.preventDefault();
        });

        var maskBehavior = function (val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        };
        $('.telefone').mask(maskBehavior, {
            onKeyPress: function (val, e, field, options) {
                field.mask(maskBehavior.apply({}, arguments), options);
            }
        });

        $('.form-cpf').mask('000.000.000-00', { reverse: true });
        $('.form-cep').mask('00000-000');
        $('.form-data').mask('00/00/0000');

        if ($('#block-views-formulario-de-avise-me-block').length) {
            var avise_me_text = $("#avise_me_text").text();
            $('.webform-component--avise-me-node input').val(avise_me_text);
        }

        // if ($( window ).width() < 1024) {
        //     $('.view-redes-sociais > .view-content > div:last-child').after('<div><a href="whatsapp://send?phone=551137993360"><i class="icon-whatsapp-squared"></i></a></div>');
        // }

        $('.open-popup-link').magnificPopup({
            type: 'inline',
            midClick: true,
            callbacks: {
                open: function () {
                    $('html').css('margin-right', 0).css('overflow', 'initial');
                }
            }
        });

    });

    //
    //  Adicionar bordas no paragraph de texto + imagem
    if ($(window).width() > 768) {
        $('.paragraphs-item-img-txt-lado-lado.bg-color-azul').after('<span class="path path--top path--back--blue path--front--dark-blue"></span><span class="path path--bottom path--back--blue path--front--dark-blue"></span>')
    }

    //
    //  Carrossel de Centro de Estudos
    //
    $(".field-name-field-introducao-carrossel > .field-items").not(".slick-initialized").slick({
        arrows: true,
        prevArrow: '<span class="slick-prev slick-arrow"><</div>',
        nextArrow: '<span class="slick-next slick-arrow">></div>',
        autoplay: false,
        autoplaySpeed: 10000,
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 1
    });

    //
    //  Carrossel de Instalações
    //
    $(".carrossel").slick({
        arrows: true,
        prevArrow: '<i class="arrow arrow--left arrow--box-white slick-arrow"></i>',
        nextArrow: '<i class="arrow arrow--right arrow--box-white slick-arrow"></i>',
        autoplay: false,
        autoplaySpeed: 10000,
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        adaptiveHeight: true
    });


    //
    //  Script para o menu superior - barra preta
    //
    // $('#block-menu-menu-superior ul.menu li.expanded').click(function(e){
    //     $(this).nextAll('ul').addClass('bolado');
    //     console.log($(this).siblings('ul'));
    //     e.stopPropagation();
    //     e.preventDefault();
    // });

    // $("#block-menu-menu-superior ul.menu li.expanded ul").hide();
    // $("#block-menu-menu-superior ul.menu li.expanded > span").click(function() {
    //     $('#block-menu-menu-superior ul.menu li.expanded').not(this).find('ul').hide();
    //     //$(this).parent().find('ul').each(function(){$(this).toggle();});
    //     $(this).siblings("ul").toggle();
    // });

    $('#block-menu-menu-superior ul.menu li.expanded').click(function () {
        $('#block-menu-menu-superior ul.menu li.expanded').not(this).find('ul').hide();
        $(this).find('ul').slideToggle();
    });
    
    $('#block-menu-menu-menu-secundario ul.menu-secondary li span.nolink').click(function () {
        $('#block-menu-menu-menu-secundario ul.menu-secondary li ul.menu-primary--submenu').not(this).find('ul').hide();
        $(this).parent().find('ul.menu-primary--submenu').slideToggle();
    });

    $(".js-modal-btn").modalVideo();

    var haveSlick = ($(".slick-slider").length > 0 ? true : false),
        siteSection = $("body.node-type-curso");

    if (!haveSlick) {
        $(".view-footer .arrow").hide();
    }


    // SE VOLTAR ESSA FEATURE, TEM QUE COMBINAR COM O SASS _EESP.SCSS LINHA 533
    // if( siteSection.length > 0 && $(window).width() < 767 ){
    //     var container = siteSection.find(".paragraphs-item-lista-de-cursos .tabs--courses");

    //     container.each(function(el){
    //         var item   = $(".field-item", this),
    //             title  = $(".course-title", item),
    //             content= $(".field-item--content", item);

    //         title.off("click").on("click", function(e){
    //             e.preventDefault();
    //             var _this = $(this),
    //                 parent= _this.parent();

    //             if(parent.hasClass('accordion-active')){
    //                 item.removeClass('accordion-active');
    //             }else{
    //                 item.removeClass('accordion-active');
    //                 _this.parent().toggleClass('accordion-active');
    //             }
    //         });
    //     });
    // }

    var contentBanner = $(".slider-banner--slide"),
        haveInfo = ($("div.course-infos", contentBanner).length > 0 ? true : false);

    if (haveInfo && $(window).width() < 767) {
        contentBanner.css("height", "400px");
    }

    // Botão de mais e menos na lista de conversa com economistas
    $('.view-lista-de-conversa-com-economistas .view-content').each(function () {
        var LiN = $(this).find('div').length;
        if (LiN > 2) {
            $('div', this).eq(2).nextAll().hide().addClass('toggleable');
            $(this).append('<div class="more"><span>+</span></li>');
        }
    });

    $('.view-lista-de-conversa-com-economistas .view-content').on('click', '.more', function () {
        if ($(this).hasClass('less')) {
            $(this).html('<span>+</span>').removeClass('less');
        } else {
            $(this).html('<span>-</span>').addClass('less');
        }
        $(this).siblings('div.toggleable').slideToggle();
    });

    $('#baixeCatalogo').click(function (e) {
        e.preventDefault();
        $('.view-webform-informa-es-do-curso .view-content').slideToggle();
        $('.view-webform-download-arquivo .view-content').slideToggle();
    });

    // Prevent Double Submits
    Drupal.behaviors.fgv_prevent_sucessive_submit = {
        attach: function (context, settings) {
            document.querySelectorAll('form').forEach(form => {
                if (!form.classList.contains('form-prevent-multi-submit')) {
                    form.classList.add('form-prevent-multi-submit');
                    form.addEventListener('submit', (e) => {
                        try {
                            // Prevent if already submitting
                            if (form.classList.contains('is-submitting')) {
                                e.preventDefault();
                                e.submitter.setAttribute("disabled", "disabled");
                                e.submitter.classList.add('submitting');
                                console.info('Successive submit suppressed');
                            }
                            // Add a visual indicator to show the user it is submitting
                            form.classList.add('is-submitting');
                        } catch (err) {
                            console.info('Error on successive submit suppressed');
                        }
                    });
                }
            });
        }
    };
    if ($(window).width() < 990) {
        $("body").bind("DOMNodeInserted", function () {
            $(this).find('#bot').attr('style', 'bottom: 60px !important');;
        });
    }

})(jQuery);



function addToCal(id) {
    jQuery.ajax({
        type: 'GET',
        url: '//' + document.domain + '/json/eventos-json/' + id,
        timeout: 45000,
        contentType: 'application/json',
        dataType: 'json',
        success: function (result) {
            result = JSON.stringify(result);
            result = JSON.parse(result);

            var calendar = createCalendar({
                options: {
                    class: 'button button--yellow',
                    id: id
                },
                data: {
                    title: result['Items'][0].evento.title,
                    start: new Date(result['Items'][0].evento.startDate),
                    duration: 0,
                    end: new Date(result['Items'][0].evento.endDate),
                    address: result['Items'][0].evento.endereco,
                    description: result['Items'][0].evento.conteudo,
                }
            });
            jQuery('.view-evento-sidebar').after(calendar);
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.log('Erro ao buscar dados do evento');
        }
    });
}

function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
}

( function( $ ) {
    $(document).ready(function() {
        function goTo(pos) {
            $('html,body').animate({
                scrollTop: pos
            }, 500, 'easeOutExpo');
        }

        $('body').on('click', '.anchor', function() {
            var target = $(this).attr('href');
            if (target == '#') {
                goTo(0);
            } else {
                goTo($(target).offset().top);
            }
        });
    });
} )( jQuery );

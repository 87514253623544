( function( $ ) {
    $(document).on('contentLoaded', function() {
        $('.carousel--video').each(function() {
            $(this).slick({
                dots: true,
                mobileFirst: true,
                prevArrow: false,
                nextArrow: false,
                speed: 500,
                swipeToSlide: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: 'unslick'
                    }
                ]
            });
        });
    });

} )( jQuery );

( function( $ ) {
    $(document).on('contentLoaded', function() {    
        $('.video-single').each(function() {
            var videoItem = $(this),
                youtubeVideo = videoItem.attr('data-video'),
                target = videoItem.attr('data-target');

            videoItem.find('.video-single--play, .video-single--title').on('click', function() {
                //debugger;
                if (window.EESPUtils.isMobile()) {
                    // No mobile, abre o vídeo em uma nova aba (para ser carregado com o aplicativo do Youtube)
                    window.open(youtubeVideo, '_blank');
                } else {
                    if (!target || window.EESPUtils.isSmallResolution()) {
                        // No desktop com resolução pequena
                        var video = videoItem.find('.video-single--video');
                        window.EESPUtils.addVideo(video, youtubeVideo);
                    } else {
                        window.EESPUtils.addVideo($(this).parents('.videos').find(target), youtubeVideo);
                    }
                }
            });

            $(window).resize(function() {
                window.EESPUtils.removeVideo(videoItem.find('.videos-single--video'));
            });
        });
    });
} )( jQuery );

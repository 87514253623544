( function( $ ) {
	$(document).on('contentLoaded', function() {
	    $('.selectbox-year').each(function() {
	        var selectbox = $(this).find('.select-field');
	        selectbox.SumoSelect();

	        selectbox.on('change', function() {
	            console.log('Carregar resultados aqui');
	        });
	    });
	});
} )( jQuery );

( function( $ ) {
    $(window).load(function () {

        var isMasonryActive = false;

        function initGrid(list) {
            isMasonryActive = true;

            list.masonry({
                itemSelector: '.gallery--item',
                percentPosition: true,
                gutter: 20,
                columnWidth: list.parent().find('.gallery--item-sizer')[0],
            });
        }

        function destroyGallery(list) {
            if (isMasonryActive) {
                isMasonryActive = false;
                list.masonry('destroy');
            }
        }

        function initGallery() {
            $('.gallery').each(function() {
                var selectbox = $(this).find('#edit-nid-entityreference-filter');
                var list = $(this).find('.gallery--list');
                var gallery = $(this);
        
                selectbox.SumoSelect();
        
                $(window).on('resize', function() {
                    if (window.EESPUtils.isMobileResolution()) {
                        destroyGallery(list);
                    } else {
                         initGrid(list);
                    }
                });
        
                if (!window.EESPUtils.isMobileResolution()) {
                    initGrid(list); 
                }
            });
        }

        initGallery();

        Drupal.behaviors.my2Behavior = {
            attach: function (context, settings) {
                initGallery();
            }
        }

    });
} )( jQuery );

( function( $ ) {
    $(document).on('contentLoaded', function() {
        var buttonMain = $('.button-main');
        var buttonMainIns = $('.slider-banner--button-inscricao');
        var buttonPosition;

         function stickButton() {
            var scrollTop = $(window).scrollTop();
            if (scrollTop > buttonPosition) {
                buttonMain.removeAttr('style');
                buttonMain.addClass('is-scrolling');
            } else {
                buttonMain.css('transition', 'none');
                buttonMain.removeClass('is-scrolling');
                setTimeout(function() {
                    buttonMain.removeAttr('style');
                }, 1);
            }
        };

        // 08/11/2018 foi trocado o botao de inscricao. a variavel buttonMain foi substituida por buttonMainIns
        function updateButtonPosition() {
            if (buttonMainIns.length) {
                buttonPosition = $(buttonMainIns).offset().top + $(buttonMainIns).height();
            }
        }

        function showForm(formContainer) {
            $('#overlay').fadeIn(500, 'easeOutQuint');
            formContainer.delay(100).fadeIn(500, 'easeOutQuint');
        }

        function hideForm(formContainer) {
            formContainer.fadeOut(500, 'easeOutQuint');
            $('#overlay').fadeOut(500, 'easeOutQuint');
        }

        function submitForm(formContainer) {
            
        }

        function initForm() {
            var container = $('.button-main--form-container');
            
            buttonMain.on('click', function(e) {
                e.preventDefault();
                showForm(container);
            });

            container.find('form').on('submit', function(e) {
                e.preventDefault();
                submitForm(container);
            });

            $('#overlay').on('click', function() {
                hideForm(container);
            });

            $('body').on('click', '.button-main--close-form', function() {
                hideForm(container);
            });

            $('body').on('submit', container.find('form'), submitForm);
        }

        $(window).unbind('scroll', stickButton);
        $(window).unbind('resize', updateButtonPosition);
        $(window).on('scroll', stickButton);
        $(window).on('resize', updateButtonPosition);

        updateButtonPosition();

        if (buttonMain.hasClass('button-main--with-form')) {
            initForm();
        }
    });
} )( jQuery );

(function ($) {

    $(document).ready(function () {
        const className = 'is-nav-opened';
        var navigationMain = $('#navigation-main');
        var openMenuButton = $('#main-nav-open');
        var overlay = $('#overlay');
        var closeButton = $('#navigation-main--close');
        var navigationMainPanel = navigationMain.find('.navigation-main--panel');
        var navigationMainContainer = navigationMain.find('.navigation-main--container');

        function showMenuAnimation() {
            $('body').addClass(className);
            overlay.fadeIn(500, 'easeOutExpo');
            closeButton.hide().delay(300).show(100);
            navigationMain.stop().delay(200).show().animate({
                left: 0
            }, 600, 'easeOutExpo');

            navigationMain.find('.navigation-main--side').css('height', navigationMainContainer.height());
            navigationMain.find('.navigation-main--painel').css('height', navigationMainContainer.height());
            navigationMain.scrollTop(0);
            $('.navigation-main--close-wrap').css('top', 0);
        }

        function hideMenuAnimation() {
            var transitionTiming;

            overlay.fadeOut(300, 'easeOutExpo');
            closeButton.fadeOut(150);

            if (window.innerWidth < 800) {
                transitionTiming = 500;
            } else {
                transitionTiming = 300;
            }

            navigationMain.stop().animate({
                left: '100%'
            }, transitionTiming, 'easeInQuint', function () {
                navigationMain.hide();
                $('body').removeClass(className);
            });
        }

        function initMenu() {
            openMenuButton.on('click', function () {
                showMenuAnimation();
            });

            closeButton.on('click', function () {
                hideMenuAnimation();
            });

            $('.navigation-main--container').on('click', function () {
                hideMenuAnimation();
            });

            $('.navigation-main--side, .navigation-main--panel').on('click', function (e) {
                e.stopPropagation();
            });

            $('.menu-primary--link, .menu-secondary--link').on('click', function () {
                hideMenuAnimation();
            });

            $(window).on('load scroll', function () {
                var scrollTop = $(window).scrollTop();
                if (scrollTop > $('#navigation-top').height()) {
                    $('.site-header').addClass('is-scrolling');
                } else {
                    $('.site-header').removeClass('is-scrolling');
                }
            });

            $('.navigation-main').on('scroll', function () {
                $('.navigation-main--close-wrap').css('top', $(this).scrollTop());
            });
        }

        initMenu();

        // Menu accordion 20210920
        $('.menu-primary--item.level-0').each(function () {
            if ($(this).has('.menu-primary--submenu').length) {
                $(this).addClass('collapseMenu');
                $(this).find('.nolink').click(function () {
                    $(this).toggleClass('open');
                    $(this).next('.menu-primary--submenu').slideToggle().promise().done(function () {
                        navigationMain.find('.navigation-main--side').css('height', navigationMainContainer.height());
                    });
                });
            }
        });

    });

})(jQuery);

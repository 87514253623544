( function( $ ) {
    $(document).on('contentLoaded', function() {
        function initVideoCarousel(list) {
            list.on('beforeChange', function(event, slick, currentSlide) {
                window.EESPUtils.removeVideo(
                    $(slick.$slides.get(currentSlide)).find('.videos--video')
                );
            });

            list.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                mobileFirst: true,
                easing: 'cubic-bezier(0.86, 0, 0.07, 1)',
                speed: 400,
                dots: true,
                arrows: false,
                centerMode: true,
                centerPadding: '15px',
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            centerPadding: '7.5%'
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: 'unslick'
                    }
                ]
            });
        }

        $('.videos').each(function() {
            var videoComponent = $(this);
            initVideoCarousel(videoComponent.find('.videos--list'));

            var firstVideo = $(this).find('.videos--item').first();
            $(this).find('.videos--panel').append(firstVideo.clone());
            firstVideo.addClass('videos--item--playing');

            $(this).find('.videos--item').on('click', function() {
                $(this).siblings().removeClass('videos--item--playing');
                $(this).addClass('videos--item--playing');
            });
        });
    });
} )( jQuery );

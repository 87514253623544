// ( function( $ ) {

//     $(document).on('contentLoaded', function() {
//         $.extend($.validator.messages, {
//             required: 'Campo obrigatório',
//             email: 'E-mail inválido',
//             date: 'Data inválida',
//             url: 'URL inválida'
//         });

//         $('.form').each(function() {
//             var _this = $(this);
//             $(this).validate({
//                 submitHandler: function(form) {
//                     var target = _this.attr('action');
//                     var dfd = $.post(target, $(this).serialize());

//                     // TODO: Alterar a forma como os erros aparecem
//                     // de acordo com as respostas enviadas pelo servidor
//                     dfd.fail(function() {
//                         console.log('Mensagem de erro');
//                     });

//                     dfd.then(function() {
//                         $(form).find('.form--success').fadeIn(300);
//                     });
//                 }
//             });

//             var textarea = $(this).find('textarea');
//                 textarea.on('keydown', autosize);
                            
//             function autosize() {
//                 var el = this;
//                 setTimeout(function(){
//                     el.style.cssText = 'height:auto; padding:0';
//                     el.style.cssText = 'height:calc(.5em + ' + el.scrollHeight + 'px)';
//                 },0);
//             }
//         }); 
//     });

// } )( jQuery );


( function( $ ) {
	$(document).ready(function() {
		$('#goToForm').click(function(e){
			e.preventDefault();
			$('.event-single-panel .registration-form').slideDown('slow');
			$('html, body').animate({
				scrollTop: $('.event-single-panel .registration-form').offset().top -200
			}, 'slow');
		});
		$("#edit-submitted-assunto-area").SumoSelect();
		
		$('#goToForm2').click(function(e){
			e.preventDefault();
			$('.node-webform .webform-client-form').slideDown('slow');
			$('.node-webform #form-candidato').slideUp();
			$('html, body').animate({
				scrollTop: $('.node-webform .webform-client-form').offset().top -400
			}, 'slow');
		});
		$("#node-21861 select").SumoSelect();
		$('.node-webform #form-candidato').click(function(e){
			e.preventDefault();
			$('.node-webform .webform-client-form').slideDown('slow');
			$('.node-webform #form-candidato').slideUp();
		});
	});
} )( jQuery );
( function( $ ) {
    $(document).on('contentLoaded', function() {
        var qtSlidesDesktop = 3;
        var qtSlidesTablet = 2;
        if ( $("body").hasClass("page-node-2983") || $("body").hasClass("page-node-20475") ) {
            qtSlidesDesktop = 4;
            qtSlidesTablet = 3; 
        }

        $('.carousel').each(function() {
            var desktopSlides = $(this).attr('data-carousel-desktop');
            var tabletSlides = $(this).attr('data-carousel-tablet');
            var tabletHSlides = $(this).attr('data-carousel-tablet-h');
            var upToTablet = $(this).hasClass('carousel--tablet-h-max');
            var mobileOnly = $(this).hasClass('carousel--tablet-v-max');
            var insideTabs = $(this).hasClass('carousel--tabs');
            var variableHeight = $(this).hasClass('carousel--adaptive-height');

            var _parent = $(this).parent();
            var el = $(this);
            var settings;

            settings = {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                mobileFirst: true,
                cssEase: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
                speed: 600,
                prevArrow: _parent.find('.arrow--left'),
                nextArrow: _parent.find('.arrow--right'),
                responsive: []
            };

            // Tablet vertical
            if (!tabletSlides) {
                tabletSlides = 2;
            }
            settings['responsive'].push({
                breakpoint: 767,
                settings: {
                    slidesToShow: tabletSlides
                }
            });

            if (!upToTablet && !mobileOnly) {
                // Não há um valor padrão para o tablet na horizontal
                if (tabletHSlides) {
                    settings['responsive'].push({
                        breakpoint: 1023,
                        settings: {
                            slidesToShow: tabletHSlides
                        }
                    });
                }

                if (!desktopSlides) {
                    desktopSlides = 4;
                }
                settings['responsive'].push({
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: desktopSlides,
                        centerMode: false
                    }
                });
            } else {
                if (upToTablet) {
                    // Destrói o carousel a partir do tablet
                    settings['responsive'].push({
                        breakpoint: 1023,
                        settings: 'unslick'
                    });
                }

                if (mobileOnly) {
                    // Destrói o carousel a partir do tablet
                    settings['responsive'].push({
                        breakpoint: 767,
                        settings: 'unslick'
                    });
                }
            }

            if ($(this).hasClass('professor-list')) {
                settings['responsive'].push({
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: qtSlidesTablet,
                        slidesToScroll: qtSlidesTablet,
                        centerMode: false
                    }
                });
                settings['responsive'].push({
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: qtSlidesDesktop,
                        slidesToScroll: qtSlidesDesktop,
                        centerMode: false
                    }
                });
            }

            if ($(this).hasClass('carousel--dots')) {
                settings['dots'] = true;
            }

            // Inclusão de carrossel com duas linhas - dezembro/2020
            if ($(this).hasClass('two-rows')) {
                settings['slidesPerRow'] = 1;
                settings['rows'] = 2;
                settings['responsive'].push({
                    breakpoint: 0,
                    settings: {
                        slidesPerRow: 1,
                        rows: 4
                    }
                });
                settings['responsive'].push({
                    breakpoint: 768,
                    settings: {
                        slidesPerRow: 1,
                        rows: 2
                    }
                });
            }

            if ($(this).hasClass('carousel--centered')) {
                settings['centerMode'] = true;
                settings['centerPadding'] = '10%';
            }

            if (variableHeight ) {
                settings['adaptiveHeight'] = true;
            }

            el.on('afterChange', function() {
                el.find('.video-single--video').html('').hide();
            });

            el.slick(settings);

            if (insideTabs) {
                $('body').on('tabs--item-selected', function(e) {
                    el.slick('destroy').slick(settings);
                });
            }

            $(window).resize(function() {
                if (el.hasClass('slick-initialized')) {
                    return;
                }

                if (upToTablet && window.innerWidth <= 1023) {
                    el.slick(settings);
                }

                if (mobileOnly && window.innerWidth <= 767) {
                    el.slick(settings);
                }
            });
        });

        $('.tabs--list li, .accordion--list li').click(function(){
            $(this).find('.carousel.slick-initialized').slick('refresh');
        });

    });
} )( jQuery );

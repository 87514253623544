(function ($) {
    $(document).ready(function () {

        $('.button-warn-me').each(function () {
            var button = $(this);
            var panel = $('.button-warn-me--content');

            function setPanelPosition(panel, button) {
                var panelPosition = button.offset().top;
                // panel.css('top', panelPosition);
                // panel.css('left', button.offset().left);
                panel.css({
                    'left': '50%',
                    'top': '40%',
                    'margin-left': -$(panel).outerWidth() / 2,
                    'margin-top': -$(panel).outerHeight() / 2
                });
            }

            function closePanel(panel) {
                $('#overlay').fadeOut(100);
                panel.fadeOut(100);
            }

            button.on('click', function (e) {
                e.preventDefault();
                setPanelPosition(panel, button);
                panel.fadeIn(150);
                $('#overlay').fadeIn(100);

                $('#overlay').one('click', function () {
                    closePanel(panel);
                });
            });

            $(window).resize(function () {
                setPanelPosition(panel, button);
            });
        });

    });

})(jQuery);

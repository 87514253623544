( function( $ ) {
    $(document).on('contentLoaded', function() {
        $('.slider').each(function() {
            var _this = $(this);
            var easing = $(this).attr('data-easing');
            var params = {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 500,
                prevArrow: _this.find('.arrow--left'),
                nextArrow: _this.find('.arrow--right')
            };

            if (easing) {
                params['cssEase'] = easing;
            } else {
                params['cssEase'] = 'cubic-bezier(0.075, 0.82, 0.165, 1)';
            }

            $(this).find('.slider--list').slick(params);
        });
    });
} )( jQuery );

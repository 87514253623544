(function ($) {
    $(function () {
        var className = 'tabs--item--is-collapsed';
        var currentItem;

        function isTablet() {
            return window.innerWidth < 1024;
        }

        function showContent(tabs, element) {
            var tab = element.parents('.tabs--item');
            currentItem = element;
            $('body').trigger('tabs--item-selected', tabs);

            if (isTablet()) {
                tab.css('height', 'auto');
                tab.removeClass(className);
                element.next('.tabs--content')
                    .stop()
                    .slideDown(500, 'easeOutCubic');
            } else {

                var firstContent = tabs.find('.tabs--item:first');
                firstContent.find('.tabs--content').css('opacity', 1);

                var currentContent = tabs.find('.tabs--item').not('.' + className).find('.tabs--content'),
                    targetContent = element.next('.tabs--content');

                if (currentContent.length > 0) {
                    currentContent.stop().fadeOut('fast', function () {
                        currentContent.parent().css('height', 'auto');
                        currentContent.parent().addClass(className);
                    });
                }

                if (targetContent.length > 0) {
                    var height = targetContent.innerHeight() + 50;
                    targetContent.parent().removeClass(className);
                    targetContent.stop().fadeIn('fast');
                    tab.css('height', height);

                    // essas alterações foram movidas para o módulo ckeditor-accordion.js
                    // $('.tabs--content .ckeditor-accordion-toggler').on('click', function () {
                    //     //console.log(itemlist.height());
                    //     var itemlist = $(this).closest('.item-list');
                    //     setTimeout(function () {
                    //         var contHeight = $('.ckeditor-accordion-container .styled').height();
                    //         tab.animate({ height: itemlist.height() + contHeight + 50})
                    //     }, 400);
                    // });

                }

                setCurrentItemIndicator(tabs, element);
            }
        }

        function hideContent(tabs, element) {
            if (isTablet()) {
                element.parent().addClass(className);
                element.next('.tabs--content').stop().slideUp(500);
            }
        }

        function setCurrentItemIndicator(tabs, target) {
            var el = tabs.parent().find('.tabs--current-item');

            if (el.length > 0) {
                var targetPos = target.position().left;
                var targetWidth = target.width();

                el.stop().animate({
                    left: targetPos + (targetWidth / 2) - (el.width() / 2)
                }, 400, 'easeOutExpo');
            }
        }

        $('.tabs').each(function () {
            var tabs = $(this);

            $(this).find('.tabs--title').on('click', function () {
                if ($(this).parent().hasClass(className)) {
                    showContent(tabs, $(this));
                } else {
                    hideContent(tabs, $(this));
                }
            });

            $(window).resize(function () {
                showContent(tabs, currentItem);
            });

            currentItem = tabs.find('.tabs--title:first');

            Drupal.behaviors.my3Behavior = {
                attach: function (context, settings) {
                    showContent(tabs, currentItem);

                }
            }
            showContent(tabs, currentItem);

        });

        // Tabs da grade curricular de cada curso - 20210923
        $('.tabGrade a').click(function () {

            $('.tabGrade li').removeClass('active');
            $(this).parent().addClass('active');

            let currentTab = $(this).attr('href');
            $('.tabGrade-content .tabGrade-div').hide();
            $(currentTab).show();

            var tabAtiva = $('.tabs--item').not('.tabs--item--is-collapsed');
            var novaAltura = tabAtiva.find('.tabs--content').innerHeight() + 50;
            tabAtiva.css('height', novaAltura);

            return false;
        });
    });
})(jQuery);

( function( $ ) {
    $(document).on('contentLoaded', function() {
        $('.slider-banner--slider').each(function() {
            var _parent = $(this).parent(),
                time = 5;
            
            var tick,
                percentTime;

            $(this).slick({
                autoplay: true,
                autoplaySpeed: 10000,
                speed: 600,
                dots: false,
                prevArrow: _parent.find('.arrow--left'),
                nextArrow: _parent.find('.arrow--right'),  
                cssEase: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
            });
        });

        $('.slider-banner--go-down').on('click', function() {
            $('html,body').animate({
                scrollTop: window.innerHeight
            }, 1000, 'easeOutExpo');
        });
    });
} )( jQuery );

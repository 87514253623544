( function( $ ) {

    $(document).on('contentLoaded', function() {
        var currrentIcon, timeout;
        var shareBar = $('#share-bar');
        
        function setShareBarPosition() {
            shareBar.css('top', currrentIcon.offset().top + 30);
        }

        function setShareBarLinks() {
            var shareUrl = currrentIcon.attr('data-url');
            var title = currrentIcon.closest('h2,h3').first();
            var titleUrl;

            if (!shareUrl) {
                shareUrl = encodeURI('http://eesp.fgv.br');
                if (title.length > 0) {
                    titleUrl = encodeURIComponent(title.text());
                } else {
                    titleUrl = '';
                }
            }

            console.log(shareUrl);

            var linkedin = 'https://www.linkedin.com/shareArticle?mini=true&url=' + shareUrl + '&title=' + titleUrl + '&summary=&source=';
            var facebook = 'https://www.facebook.com/sharer/sharer.php?u=' + shareUrl;
            var twitter =  'https://twitter.com/home?status=' + titleUrl + '%20' + shareUrl;
            var email = 'mailto:?&subject=' + titleUrl + '&body=' + shareUrl;

            shareBar.find('.facebook a').attr('href', facebook);
            shareBar.find('.twitter a').attr('href', twitter);
            shareBar.find('.linkedin a').attr('href', linkedin);
            shareBar.find('.email a').attr('href', email);
        }

        $('body').on('click', '.share-icon', function() {
            currrentIcon = $(this);
            setShareBarLinks();
            clearTimeout(timeout);

            if (shareBar.hasClass('show')) {
                shareBar.fadeOut(200, function() {
                    setShareBarPosition();
                    shareBar
                        .removeClass('show')
                        .show()
                        .addClass('show');
                });
            } else {
                setShareBarPosition();
                shareBar.addClass('show');
            }

            timeout = setTimeout(function() {
                shareBar.removeClass('show');
            }, 6000);
        });

        shareBar.on('click', function() {
            shareBar.removeClass('show');
        });

        $(document).on('keyup', function(e) {
            if (e.keyCode == 27) { // escape key maps to keycode `27`
                shareBar.removeClass('show');
            }
        });

        $('#share-bar a').on('click', function(e) {
            e.stopPropagation();
        });

        $(window).resize(function() {
            if (currrentIcon) {
                setShareBarPosition();
            }
        });
    });

} )( jQuery );

    var EESPUtils = {
        isMobile: function() {
            return (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));
        },

        isMobileResolution: function() {
            return window.innerWidth < 768;
        },

        isSmallResolution: function() {
            return window.innerWidth < 1024;
        },

        // getMonthList: () => {
        //     return ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        // },

        // getWeekdaysList: () => {
        //     return ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
        // },

        // initLoading: () => {
        //     $('.loading-bar--progress').css('width', 0);
        //     $('.loading-bar').show();
        //     $('.loading-bar--progress').animate({
        //         width: '100%'
        //     }, 7000);
        // },

        // stopLoading: () => {
        //     $('.loading-bar--progress').stop().animate({
        //         width: '100%'
        //     }, 300, function() {
        //         $('.loading-bar').hide();
        //     });
        // },

        removeVideo: function(videoContainer) {
            videoContainer.fadeOut(300, function() {
                $(this).find('iframe').remove();
            });
        },

        addVideo: function(videoContainer, youtubeVideo) {
            videoContainer.fadeIn(300);
            videoContainer.html(
                '<iframe width="100%" height="' + videoContainer.height() + '" src="' + youtubeVideo + '?autoplay=1&rel=0&showinfo=0&modestbranding=1" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>'
            );
        }
    };


// ( function( $ ) {
// 	$(document).ready(function() {
// 	    // $('.selectbox-links').each(function() {
// 	    //     var selectField = $(this).find('.select-field');
// 	    //     selectField.on('change', function() {
// 	    //         if ($(this).val() !== '') {
// 	    //             window.location = $(this).val();
// 	    //         }
// 	    //     });

// 	    //     selectField.SumoSelect();
// 	    // });
// 	    $('#edit-jump').SumoSelect();
// 	    $('#edit-jump--2').SumoSelect();
// 	    $('#edit-field-categoria-professor-tid').SumoSelect();
// 	});
// } )( jQuery );


( function( $ ) {

    Drupal.behaviors.addSumo = {
      attach: function (context, settings) {
	    $('#edit-jump').SumoSelect();
	    $('#edit-jump--2').SumoSelect();
	    $('#edit-jump--3').SumoSelect();
	    $('#edit-field-categoria-professor-tid').SumoSelect();
      }
    };

} )( jQuery );

(function ($) {

  function ativaBotao(val) {
    $('#edit-field-evento-data-value-value-date').val(val);
    $('#edit-submit-eventos-boxes').trigger('click');
  }

  Drupal.behaviors.Events = {
    attach: function () {
      $('#edit-tid').SumoSelect();
      $('.view-eventos-calendario .date-heading a').removeAttr('href');

      $('.view-eventos-calendario .mini-day-on').on('click', function (a) {
        a.preventDefault();
        $('td.has-events').not('.future').not(this).removeClass('today').addClass('past');
        var dataVal = $(this).attr("data-value");
        var dataForm = $('#edit-field-evento-data-value-value-date').val();
        if (dataVal == dataForm) {
          ativaBotao('');
          $(this).parent().parent().not('.future').toggleClass('past today');
          $('td.has-events').not(this).removeClass('today').addClass('past');
          $('td.future').not(this).removeClass('past');
          $(this).parent().parent('.future').removeClass('today');
        } else {
          ativaBotao(dataVal);
          $(this).parent().parent().toggleClass('past today');
          $(this).parent().parent('.future').addClass('today').removeClass('past');
        }
      });

      $('.view-eventos-calendario .date-prev a, .view-eventos-calendario .date-next a').on('click', function (b) {
        b.preventDefault();
        ativaBotao('');
      });
    }
  };

})(jQuery);
( function( $ ) {
    $(document).on('contentLoaded', function() {
        // $('.expandable').each(function() {
        //     var panel = $(this);
        //     $(this).css('height', '300px');

        //     $(this).next().find('.expand--button').on('click', function() {
        //         panel.css('height', 'auto');
        //         $(this).parent().fadeOut(150);
        //     });
        // });

        $('.expand--button--professor').on('click', function() {
            $(this).parent().fadeOut(150);
        });
    });

} )( jQuery );

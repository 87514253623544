(function ($) {
    $(document).on('contentLoaded', function () {
        $('.slide').each(function () {
            var slideContainer = $(this);

            $(this).on('init', function (e, slick) {
                var slides = slick.$slides;
            });

            $(this).slick({
                dots: true,
                infinite: true,
                speed: 700,
                slidesToShow: 1,
                prevArrow: '',
                nextArrow: '',
                cssEase: 'cubic-bezier(0.075, 0.82, 0.165, 1)'
            });

            $(this).find('.slick-slide').not('.slick-cloned').find('.depoiments--item').each(function (i) {
                // Remoção do background dos depoimentos
                //var bgImage = $(this).attr('data-image');
                var thumbImage = $(this).attr('data-image-thumb');

                //$(this).css('background-image', 'url(\'' + bgImage + '\')');
                var dot = slideContainer.find('.slick-dots li').eq(i);
                dot.css('background-image', 'url(\'' + thumbImage + '\')');
            });
        });
    });
})(jQuery);

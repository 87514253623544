( function( $ ) {
    $(document).on('contentLoaded', function() {

        $('.button-schedule').each(function() {
            var button = $(this);
            var panel = $('.button-schedule--content');

            function setPanelPosition(panel, button) {
                var panelPosition = button.offset().top;
                panel.css('top', panelPosition);
                panel.css('left', button.offset().left);
            }

            function closePanel(panel) {
                $('#overlay').fadeOut(100);
                panel.fadeOut(100);
            }

            button.on('click', function() {
                setPanelPosition(panel, button);
                panel.fadeIn(150);
                $('#overlay').fadeIn(100);

                $('#overlay').one('click', function() {
                    closePanel(panel);
                });
            });

            $(window).resize(function() {
                setPanelPosition(panel, button);
            });
        });

    });

} )( jQuery );

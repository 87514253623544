( function( $ ) {

    $(document).ready(function() {
        function closeSearchPanel() {
            $('#overlay').fadeOut(200);
            $('.search-panel').fadeOut(200);
            $('.search-panel').removeClass('search-panel--show-results');
            $('body').removeClass('is-nav-opened');
            $('.search-panel--results-container').html('');
            $('.search-panel--text-field').val('');
            $('.search-panel--close').removeClass('search-panel--close--fixed');
        }

        function showSearchPanel() {
            $('.search-panel').fadeIn(300);
            $('#overlay').fadeIn(200);
            $('body').addClass('is-nav-opened');
        }

        $('.site-header--icon-search').on('click', function(e) {
            e.preventDefault();
            showSearchPanel();
        });

        $('.search-panel form').on('submit', function(e) {
            //e.preventDefault();
            //$('.search-panel--loading').fadeIn(300);

            // TODO: Substituir a URL
            //const searchUrl = './content/search.php';
            var dfd = $.get(searchUrl, {s: $('.search-panel--text-field').val()});
            dfd.then(function(html) {
                $('.search-panel').addClass('search-panel--show-results');
                $('.search-panel--loading').fadeOut(300);
                $('.search-panel--results-container').html(html);
                $('.search-panel--close').addClass('search-panel--close--fixed');
            });
        });

        $('.search-panel--icon-search').on('click', function() {
            $('.search-panel form').submit();
        });

        $('.search-panel--close, #overlay').on('click', function() {
            closeSearchPanel();
        });
    });

} )( jQuery );

( function( $ ) {
    $(document).on('contentLoaded', function() {
        $('.timeline').each(function() {
            var _this = $(this);
            var easing = $(this).attr('data-easing');
            var params = {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 500,
                arrows: false,
                asNavFor: '.timeline--nav',
            };

            if (easing) {
                params['cssEase'] = easing;
            } else {
                params['cssEase'] = 'cubic-bezier(0.075, 0.82, 0.165, 1)';
            }

            $(this).find('.timeline--list').slick(params);

            $(this).find('.timeline--nav').slick({
                slidesToShow: 8,
                slidesToScroll: 1,
                asNavFor: '.timeline--list',
                arrows: false,
                dots: false,
                focusOnSelect: true,
                variableWidth: false,
                responsive: [
                    {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        arrows: true,
                        centerMode: true,
                        infinite: true,
                        variableWidth: true
                    }
                    }
                ],
            });               
        });    

        $('.timeline--nav--link').click(function(e) {
            e.preventDefault();
            var index = $(this).data('target');
            $('.timeline--nav').slick('slickGoTo', index);
        });     
    });
} )( jQuery );

(function ($) {

    $(document).on('contentLoaded', function () {
        $('.accordion, .tabs--item').each(function () {
            var accordion = $(this);
            const className = 'accordion--item--is-collpased';

            function getOpenedItems() {
                var openedItems = accordion.find('.accordion--item').not('.' + className);
                return openedItems;
            }

            function getScrollOffset(targetItem) {
                var items = getOpenedItems();
                var offset = 0;

                items.each(function () {
                    if ($(this).index() < targetItem.index()) {
                        offset += $(this).height();
                    }
                });

                return offset;
            }

            function closeOthers() {
                var openedItems = getOpenedItems();
                openedItems.each(function () {
                    var content = getItemContent($(this));
                    closeItem($(this), content);
                });
            }

            function closeItem(item, content) {
                content.slideUp(300, 'easeOutQuart');
                item.addClass(className);
            }

            function getItemContent(item) {
                var content = item.find('.accordion--content');
                return content;
            }
            var offset = 0;
            function scrollToItem(item, offset) {
                var siteHeaderHeight = $('.site-header').height();
                offset = Math.max(50, offset - 10);

                $('html,body').animate({
                    scrollTop: item.offset().top - siteHeaderHeight - offset
                }, 300, 'easeOutQuart');
            }

            $(this).find('.accordion--title').each(function () {
                var item = $(this).parent();
                $(this).on('click', function () {
                    var content = getItemContent(item);
                    var scrollOffset = getScrollOffset(item);

                    if (content.is(':hidden')) {

                        // setTimeout(function(){
                        //     var accordionHeight = $('.accordion--content').height();
                        //     var tabHeight = $('.tabs--item').not('.tabs--item--is-collapsed');
                        //     var heightCalc = tabHeight.height() + accordionHeight;
                        //     tabHeight.animate({"height" : heightCalc});
                        //     //console.log(accordionHeight);
                        //     //console.log(tabHeight.height());
                        // }, 400);


                        closeOthers();

                        content.slideDown(300, 'easeOutQuart');
                        var carousel = content.find('.carousel');
                        if (carousel.length) {
                            carousel.get(0).slick.setPosition();
                        }

                        scrollToItem(item, scrollOffset);
                        item.removeClass(className);
                    } else {
                        closeItem(item, content);

                        // setTimeout(function(){
                        //     var accordionHeight2 = $('.accordion--content').height();
                        //     var tabHeight2 = $('.tabs--item').not('.tabs--item--is-collapsed');
                        //     var heightCalc2 = tabHeight2.height() - accordionHeight2;
                        //     tabHeight2.animate({"height" : heightCalc2});
                        // }, 400);

                    }
                });
            });
        });

        $('.view-cursos-aperfeicoamento .views-field-title .field-content').click(function () {
            $(this).toggleClass('active');
            $(this).parent().next().slideToggle();
            return false;
        });
        var animationIsOff = $.fx.off;
        $.fx.off = true;
        $('.view-cursos-aperfeicoamento .views-field-title .field-content:eq(0)').click()
        $.fx.off = animationIsOff;

    });

})(jQuery);
